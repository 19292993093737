
@import '~lessVars';

.header {
	display: inline-block;
	width: 100%;
	padding: 25px 0;
	text-align: center;
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	font-size: @fontSize_M4;
}

.countryList {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
	position: relative;
	display: grid;
	border-top: 1px solid #e5e3de;
	border-left: 1px solid #e5e3de;

	@media @desktop {
		grid-template-columns: 1fr 1fr;
	}
	@media @mobile {
		grid-template-columns: 1fr;
	}
}
