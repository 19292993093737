
@import '~lessVars';
.upload-qr {
	background-color: var(--background-primary);
	color: var(--font-primary);
	display: flex;
	flex-direction: column;
	border-radius: 24px;
	padding: 8px 12px;
	gap: 24px;
}

.upload-qr-header {
	display: flex;
	justify-content: space-between;
}

.upload-qr-header-text {
	color: var(--font-neutral-black1);
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	font-size: @fontSize_M;
	line-height: 28.8px;
}

.upload-qr-close {
	color: var(--icon-neutral-black1);
	width: 13.8px;
	height: 13.8px;

	&:hover {
		cursor: pointer;
		opacity: 0.3;
	}
}

.upload-qr-body {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.upload-qr-image-container {
	padding: 44px 0px 36px 0px;
	display: flex;
	justify-content: center;
}

.upload-qr-image {
	max-height: 225px;
	max-width: 225px;
}

.upload-qr-image-text {
	text-align: center;
	color: var(--font-neutral-black1);
	font-family: @fontFamilyDemiBold;
	font-weight: @fontWeightDemiBold;
	font-size: @fontSize_S1;
	line-height: 29px;
}

.upload-qr-footer {
	padding: 16px 12px 8px 12px;
	border-top: 1px solid var(--neutral1-borders-and-stroke);
	display: flex;
	gap: 8px;
	display: flex;
	justify-content: center;

	& span, a {
		text-align: center;
		color: var(--font-neutral-black1);
		font-family: @fontFamilyDemiBold;
		font-weight: @fontWeightDemiBold;
		font-size: calc(@fontSize_XS1 + 1px);
		line-height: 19.2px;
	}
}

.upload-qr-link {
	text-decoration: none;

	&:hover {
		opacity: 0.3;
		cursor: pointer;
	}
}
