
@import '~lessVars';
@import '../../css/components/button.less';

.textIntro {
	& when(@brand="Hema") {
		// Overrule lowercase setting for HEMA
		text-transform: none;
	}
}
.or {
	margin-top: 40px;
	margin-bottom: 20px;
	text-align: center;
	width: 100%;
	float: left;

	hr {
		width: 100%;
		height: 1px;
		border: 0;
		border-top-width: 1px;
		border-style: solid;
		padding: 0;
		border-color: @dialog_body_hr;
	}

	span {
		background: @dialog_body_bg;
		padding: 0 20px;
		position: relative;
		top: -20px;
	}
}
.registerButtons {
	float: left;
	display: flex;
	width: 100%;
	margin-top: 15px;

	.registerButtonWrapper {
		width: 100%;
		padding-left: 5px;
	}
	.loginButtonWrapper {
		width: 100%;
		padding-right: 5px;
	}
}
.primaryButton {
	.actionbutton;

	width: 100%;
	white-space: nowrap;
}
.secondaryButton {
	width: 100%;
	height: 50px;
	padding: 0 20px;
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	font-size: @fontSize_XS2;
	border-radius: 5px;
	cursor: pointer;
	border: 0;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

	background-color: #f0f0f0;
	color: #282828;

	& when(@brand="Hema") {
		text-transform: lowercase;
		background-color: #9b9b9b;
		color: #fff;
	}
}
.subscript {
	display: inline-block;
	color: #666666;
	text-align: left;
	width: 100%;
	padding: 10px 0 5px;
	font: @button_subscript_font;
	text-transform: none;
}
