
.progressDialog {
	.progressBar {
		background-color: #f1efeb;
		display: block;
		height: 15px;
		margin: 5px auto;
		max-width: 500px;
		width: 100%;
	}

	.progressValue {
		background-color: #5dc5e3;
		display: block;
		height: inherit;
	}
}
