
.promptDialog {
	.promptWrapper {
		float: left;
		text-align: left;
		width: 100%;

		&:not(:last-child) {
			padding-bottom: 15px;
		}
	}
}
