
@import '~root/css/vendor/pikaday.css';

input.date-picker.calendar-icon {
	background-repeat: no-repeat;
	background-position: right;
}
.date-picker {
	width: 150px;
	margin-right: 0;
	margin-left: 0;
	border: 1px solid #ccc;
	padding: 9px 0;
	line-height: 1.1;
	color: #444;
	border-radius: 3px;
	text-indent: 13px;
	cursor: pointer;
}
