
@import '../../css/components/button.less';
@import '../../css/components/dialog.less';

.loadingDialog {
	margin: 0;
	position: fixed;
	top: ~"calc(50% - 32px)";
	left: ~"calc(50% - 32px)";
    font-size: 1em;
    width: 64px;
    height: 64px;

    .loaderText {
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #ccc;
        padding-top: 5px;
    }
}
