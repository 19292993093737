
@import '~lessVars';

.dialog-container {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	pointer-events: none;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: @dialogZindex;

	&.dialog-modal {
		background-color: rgba(0, 0, 0, 0.5);
		pointer-events: initial;
	}

	.dialog-component {
		--dialog-component-row-gap: 20px;
		background-color: var(--background-primary);
		box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.16);
		color: var(--font-primary);
		display: flex;
		flex-direction: column;
		max-height: var(--dialog-component-max-height);
		padding: var(--dialog-component-padding-y) var(--dialog-component-padding-x);
		row-gap: var(--dialog-component-row-gap);

		.dialog-component-header {
			font-size: var(--font-size-m);

			.dialog-component-header-container {
				align-items: center;
				display: flex;
				font-size: inherit;

				&:not(.dialog-component-header-container-no-title) {
					justify-content: space-between;
				}

				&.dialog-component-header-container-no-title {
					justify-content: flex-end;
				}

				.dialog-component-close-icon {
					cursor: pointer;
					font-size: inherit;
				}

				.dialog-component-header-title {
					font-family: @fontFamilyBold;
					font-size: inherit;
					font-weight: @fontWeightBold;
				}
			}
		}

		.dialog-component-body {
			--dialog-component-body-max-height: calc(var(--dialog-component-max-height) - calc(var(--dialog-component-padding-y) * 2) - 24px - var(--dialog-component-row-gap));

			pre {
				font-family: @fontFamilyRegular;
				font-weight: @fontWeightRegular;
				font-size: @fontSize_S_New;
				white-space: pre-wrap;
				word-wrap: break-word;
			}
		}

		.dialog-component-footer {
			&:not(.dialog-component-footer-buttons-small) {
				.dialog-component-footer-buttons {
					.dialog-component-footer-button {
						flex-basis: calc(50% - 8px);
						flex-grow: 1;
					}
				}
			}

			.dialog-component-footer-buttons {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
				justify-content: center;
			}
		}
	}
}
