/* Frame 1 */
/* Shelf */
.loadingBar {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
}
.loadingBar:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}
@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
.loadWrapper {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 30px;
}
.picker {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  display: grid;
}
.picker.one {
  grid-template-columns: auto;
  row-gap: 20px;
}
.picker.two {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}
.picker.three {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}
.picker .pickerItem {
  display: inline-block;
  padding: 0;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.picker .pickerItem .selectedIcon {
  position: absolute;
  right: calc(50% - 16px);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: #fff;
  text-align: center;
  color: #9ac122;
  cursor: pointer;
}
.picker .pickerItem .selectedIcon i {
  line-height: 32px;
}
