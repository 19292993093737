
@import '~lessVars';
@import '~root/css/components/button.less';

.framePickerView {
	@media @mobile {
		height: 100vh;
	}
}
.header {
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	margin-left: 16px;

	@media @desktop {
		font-size: @fontSize_M;
	}
	@media @mobile {
		font-size: @fontSize_M4;
	}
}
.closer {
	width: 100%;
	text-align: right;

	.icon {
		font-family: "Material Icons";
		cursor: pointer;
		color: #9B9B9B;
		position: relative;

		@media @desktop {
			font-size: @fontSize_M;
			padding: 4px;
			border-radius: 14px;
			background-color: #9B9B9B;
			color: #fff;
			right: 16px;
			top: 16px;
		}
		@media @mobile {
			font-size: @fontSize_M4;
			right: 10px;
			top: 10px;
		}
	}
}
.priceContainer {
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	font-size: @fontSize_XXL;
	width: 100%;

	@media @desktop {
		position: absolute;
		bottom: 60px;
	}
	@media @mobile {
		position: fixed;
		bottom: 75px;
	}

	/* Hide price container on small mobile screens since there isn't enough space */
	@media screen and (max-height: 700px) {
		display: none;
	}
}
.buttonContainer {
	@media @desktop {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding-right: 16px;
	}
	@media @mobile {
		position: fixed;
		bottom: 16px;
		left: 16px;
		right: 16px;
	}
}
.content {
	display: flex;
	align-items: stretch;

	@media @desktop {
		padding: 16px;
	}
	@media @mobile {
		padding: 5px 0;
	}

	@media @mobile {
		flex-direction: column;
	}
}
.twodViewWrapper {
	overflow: hidden;
	position: relative;

	@media @desktop {
		width: 473px;
		height: 473px;
	}
	@media @mobile {
		width: 100%;
		height: 100vw;
		max-height: 40vh;
	}
}
.frameOptionsContainer {
	position: relative;

	@media @desktop {
		width: 246px;
		padding-left: 16px;
	}
	@media @mobile {
		width: 100%;
		padding: 0 16px;
	}
}
.frameOptions {
	width: 100%;
	font-size: @fontSize_XS2;
	font-family: @fontFamilyRegular;
	font-weight: @fontWeightRegular;
	list-style-type: none;
	margin: 0;
	padding: 0;

	@media @desktop {
		max-height: 345px;
		overflow-y: auto;
		overflow-x: hidden;
	}
	@media @mobile {
		// Make sure action button is not overlaying the options in the list
		padding-bottom: 70px;
	}
}
.frameOption {
	list-style-type: none;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	width: 100%;
	border: 1px solid #E5E3DE;
	box-sizing: border-box;
	border-radius: 2px;
	height: 60px;

	@media @desktop {
		margin-bottom: 20px;
	}
	@media @mobile {
		margin-bottom: 10px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.selected {
		border-color: #323232;
	}

	.variantImageHolder {
		width: 70px;
		height: 60px;
		line-height: 60px;
		padding-left: 10px;
		padding-right: 10px;
		text-align: center;

		img {
			vertical-align: middle;
			display: inline-block;
			max-width: 48px;
			max-height: 36px;
		}
	}
	.label {
		flex: 1;
	}
}
