
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/login.less';

.instructions {
	width: 100%;
	float: left;
	text-align: left;
	font-size: 0.8em;
}
.buttonContainer {
	width: 100%;
	float: left;
	padding-top: 15px;
}
.existingUserLink {
	& when(@brand="Hema") {
		text-transform: none;
	}
}
