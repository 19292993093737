
@import '~lessVars';
@import '~root/css/components/animation.less';

.spinner-container {
	float: left;
	width: 100%;
	position: relative;
	height: 164px;
}

.spinner, .icon{
	position: absolute;
}
