
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/login.less';

.loginFormFooter {
	padding-top: 10px;
	font-family: @fontFamilyDemiBold;
	font-weight: @fontWeightDemiBold;

	.forgotPasswordLink {
		margin-left: 15px;
		cursor: pointer;
	}
}
