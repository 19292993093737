
@import '~lessVars';

.price-view {
	display: flex;

	sup {
		display: inline-block;
		font-size: 60%;
		left: -0.35em;
		line-height: 100%;
		position: relative;
		top: -0.4em;
		vertical-align: baseline;

		&:not(.decimals) {
			transform: scaleY(1.4);
		}
	}

	.priceUnits {
		span {
			float: left;
		}

		.units {
			& when(@brand="Hema") {
				margin-right: 2px;
			}
		}
	}
}
