
@import '../../../css/components/animation.less';

.TwoDView {
	overflow: hidden;
	width: 100%;
}
.fullCanvas {
    display: none;
}
.space2d {
	width: 100%;

	canvas {
		outline: none;
	}
}
.loaderBox {
	position: relative;
	width: 100%;

	.loadIconSvg {
		position: absolute;
		top: ~"calc(50% - 32px)";
		left: ~"calc(50% - 32px)";
	}
	.loadIcon {
		.animation-spinner;

		box-sizing: border-box;
		stroke: #A9A9A9;
		stroke-width: 3px;
		transform-origin: 50%;
	}
}
.offeringFrame {
	position: absolute;
}
