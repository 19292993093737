@import "./less.less";
@import "../defaults/css.less";

:global {
	:root {
		--font-family-regular: @fontFamilyRegular;
		--font-family-demi-bold: @fontFamilyDemiBold;
		--font-family-bold: @fontFamilyBold;
		--font-weight-regular: @fontWeightRegular;
		--font-weight-demi-bold: @fontWeightDemiBold;
		--font-weight-bold: @fontWeightBold;

		.light {
			// Background
			--background-primary: #fff;
			--background-secondary: #f6f6f6;
			--background-tertiary: #fff;

			// Font
			--font-primary: #252525;
			--font-secondary: #fff;
			--font-disabled: rgba(#252525, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #252525;
			--font-neutral-grey1: #808080;


			// Icon
			--icon-default: #252525;
			--icon-active: #252525;
			--icon-disabled: rgba(#252525, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #252525;
			--icon-neutral-grey1: #f6f6f6;
			--icon-neutral-inverse: #fff;

			// Neutrals
			--white1: #fff;
			--black1: #252525;
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#262626, 0.1);
			--neutral3-background-fill: #f0f0f0;
			--neutral4-grey: #808080;
			--neutral6-grey-fill: #dcdcdc;
			--neutral7-neutral-fill: #252525;
			--neutral8-neutral-fill2: #fff;

			// Menu
			--menu-default: #252525;
			--menu-active: #dc2238;
			--menu-disabled: rgba(#252525, 0.3);

			// Colors
			--primary1: #dc2238;
			--primary2: #7c2628;
			--accent1: #dc2238;
			--accent2: #c21e31;
			--accent3: #f4b73c;
			--accent4: #ed1b2e;
			--alert-info: #3333ff;
			--alert-warning: #f4b73c;
			--alert-warning2: #ff5c5c;
			--gridlines: #ff3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);
		}

		.dark {
			// Background
			--background-primary: #181818;
			--background-secondary: #262626;
			--background-tertiary: #181818;

			// Font
			--font-primary: #fff;
			--font-secondary: #252525;
			--font-disabled: rgba(#fff, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #252525;
			--font-neutral-grey1: #808080;

			// Icon
			--icon-default: #fff;
			--icon-active: #252525;
			--icon-disabled: rgba(#fff, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #252525;
			--icon-neutral-grey1: #f6f6f6;
			--icon-neutral-inverse: #252525;

			// Neutrals
			--white1: #fff;
			--black1: #252525;
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#fff, 0.1);
			--neutral3-background-fill: #f0f0f0;
			--neutral4-grey: #808080;
			--neutral6-grey-fill: #dcdcdc;
			--neutral7-neutral-fill: #fff;
			--neutral8-neutral-fill2: #262626;

			// Menu
			--menu-default: #fff;
			--menu-active: #dc2238;
			--menu-disabled: rgba(#fff, 0.3);

			// Colors
			--primary1: #dc2238;
			--primary2: #7c2628;
			--accent1: #dc2238;
			--accent2: #c21e31;
			--accent3: #f4b73c;
			--accent4: #ed1b2e;
			--alert-info: #3333ff;
			--alert-warning: #f4b73c;
			--alert-warning2: #ff5c5c;
			--gridlines: #ff3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);
		}
	}
}
