
@import '~lessVars';
@import '../../css/components/animation.less';

.loadIconBox {
    position: relative !important;
    top: 50%;
    transform: translateY(-50%);
}

.loadIcon {
    .animation-spinner;

    box-sizing: border-box;
    stroke: #ccc;
    stroke-width: 3px;
    transform-origin: 50%;
}
