
@import '~lessVars';
@import '~root/css/components/animation.less';
@import '~root/css/components/button.less';
@import '~root/css/components/loadbar.less';
@import '~root/css/components/view-wrapper.less';

.subscriptionsView {
	.checkboxHolder {
		float: left;
		width: 100%;
		padding-bottom: 15px;
		text-align: left;
		font-size: @fontSize_XS2;

		input {
			width: auto !important;
		}
	}
	.saveSpinner {
		color: black;
	}
}
