
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/input.less';

.actionbutton {
    margin-top: 15px;
}
.editbox,
.content {
    float: left;
    width: 100%;
}
.editbox .td:first-child {
	@media @mobile {
		display: none;
	}
}
.editbox .td:nth-child(2) {
    @media @desktop {
        padding-left: 5px;
    }
	@media @mobile {
		width: 100%;
	}
}
input {
	box-sizing: border-box;
    text-align: left;
	padding: 0 14px;
	height: 40px;
    border: 1px solid #ccc;
    width: 100%;
    margin: 2px 0;
	border-radius: 0;
	font-family: @fontFamilyRegular;
	font-weight: @fontFamilyRegular;
	font-size: @fontSize_XS1;
}
.selectBox {
	width: 100%;
	margin: 2px 0;
	text-transform: capitalize;
}
.inputError {
	display: inline-block;
	box-sizing: border-box;
    width: 100%;
    text-align: left;
    font-size: 0.8em;
	color: red;

    @media @desktop {
        padding: 0 5px;
    }
}
