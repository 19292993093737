.inputGroup {
	position: relative;
	margin-top: 5px;
	width: 100%;
	float: left;

	.flexRow {
		display: flex;
		flex-flow: row;

		.flexGroup {
			display: inline-block;
			width: 100%;
			/* Make sure the groups combined are stretch to the end */

			&:not(:first-child) {
				padding-left: 2px;
			}

			&:not(:last-child) {
				padding-right: 2px;
			}
		}
	}

	.inputLabel {
		float: left;
		width: 100%;
		text-align: left;
	}

	input {
		padding: 5px;
		width: 100%;
		box-sizing: border-box;
	}
}

.selectBox {
	height: 40px;
	font-family: @fontFamilyRegular;
	font-weight: @fontWeightRegular;
	font-size: @fontSize_XS1;
	box-sizing: border-box;
	text-align: left;
	padding: 0 24px 0 14px;
	border-radius: 2px;
	border: 1px solid #c6c6c6;
	appearance: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='292.4' height='292.4'%3E%3Cpath fill='%23000' d='M287 69.4a17.6 17.6 0 0 0-13-5.4H18.4c-5 0-9.3 1.8-12.9 5.4A17.6 17.6 0 0 0 0 82.2c0 5 1.8 9.3 5.4 12.9l128 127.9c3.6 3.6 7.8 5.4 12.8 5.4s9.2-1.8 12.8-5.4L287 95c3.5-3.5 5.4-7.8 5.4-12.8 0-5-1.9-9.2-5.5-12.8z'/%3E%3C/svg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;

	& when(@brand="Hema") {
		text-transform: lowercase;
	}
}
