
@import '~lessVars';
.upload-progress {
	background-color: var(--background-primary);
	width: 100%;
	color: var(--font-primary);
	border-radius: 24px;
	padding: 24px 32px;

	@media @mobile {
		padding: 60px 20px 0 20px;
		border-radius: 0;
		height: 100vh;
		display: flex;
		flex-direction: column;
	}
}

.upload-progress-header-container.center{
	@media @mobile {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 32px;
		justify-content: center;
	}
}

.upload-progress-header {
	display: flex;
	justify-content: space-between;

	@media @mobile {
		justify-content: center;
	}
}

.upload-progress-header-text {
	color: var(--font-neutral-black1);
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	font-size: @fontSize_M;
	text-align: center;
	line-height: 28.8px;
}

.upload-progress-close {
	color: var(--icon-neutral-black1);
	width: 13.8px;
	height: 13.8px;

	&:hover {
		cursor: pointer;
		opacity: 0.3;
	}

	@media @mobile {
		display: none;
	}
}

.upload-progress-subheader {
	color: var(--font-neutral-black1);
	font-weight: @fontWeightRegular;
	font-family: @fontFamilyRegular;
	text-align: center;
	font-size: calc(@fontSize_XS1 + 1px);
}

.upload-progress-body {
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media @mobile {
		flex: 1;
		padding-bottom: 60px;
		justify-content: center;
	}
}

.upload-progress-spinner{
	@media @mobile {
		margin: 120px 0;
	}
}

.upload-progress-photos {
	display: flex;
	justify-content: space-evenly;
	margin: 43px 0 43px 0;
	width: 100%;
	flex-wrap: nowrap;
	white-space: no-wrap;
	overflow: hidden;
	column-gap: 15px;

	.thumb {
		float: left;
		max-width: 120px;
		max-height: 80px;

		&.top {
			padding-bottom: 15px;
		}
		&.bottom {
			padding-top: 15px;
		}
	}
}

.upload-progress-photo {
	max-width: 100%;
	height: auto;
}

.upload-progress-footer {
	padding: 32px 0;
	display: flex;
	justify-content: center;
}
