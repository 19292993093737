
.icon-component {
	@flip: scaleX(-1);
	@flop: rotate(0.5turn);
	user-select: none;

	&.icon-component-flip.icon-component-flop {
		transform: @flip @flop;
	}

	&.icon-component-flip:not(.icon-component-flop) {
		transform: @flip;
	}

	&.icon-component-flop:not(.icon-component-flip) {
		transform: @flop;
	}
}
