/* FLASHING */

@keyframes flash {
    0%, 100%, 50% {
        opacity: 1;
    }

    25%, 75% {
        opacity: 0;
    }
}

.animation-flash {
    animation: flash 2s ease infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(450deg);
    }
}
@keyframes line {
    0% {
        stroke-dasharray: 2, 85.964;
        transform: rotate(0);
    }
    50% {
        stroke-dasharray: 65.973, 21.9911;
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dasharray: 2, 85.964;
        stroke-dashoffset: -65.973;
        transform: rotate(90deg);
    }
}

.animation-spinner {
	will-change: transform;
    animation: line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, rotate 1.6s linear infinite;
}

@keyframes color {
    0% {
            color: #FFEBAE;
        }
    25% {
            color: #AFE89E;
        }
    50% {
            color: #BBF7FF;
        }
        75% {
            color: #B89EE8;
        }
        100% {
            color: #FFCCCA;
        }
}

.animation-color {
    animation-name: color;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@keyframes scale {
    0% {}
    50% {transform: scale(0.5)}
}

.animation-scale {
    animation-name: scale;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@keyframes bounce-left {
    0%, 100% {
        transform: translateX(0);
    }
    25%, 75% {
        transform: translateX(-50%);
    }
    50% {
        transform: translateX(-25%);
    }
}

.bounceLeft {
    animation-name: bounce-left;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes interval-bounce-left {
    0%, 25% {
        transform: translateX(0);
    }
    10%, 20% {
        transform: translateX(-50%);
    }
    15% {
        transform: translateX(-25%);
    }
}

.intervalBounceLeft {
    animation-name: interval-bounce-left;
    animation-duration: 6s;
    animation-iteration-count: infinite;
}

@keyframes bounce-right {
    0%, 100% {
        transform: translateX(0);
    }
    25%, 75% {
        transform: translateX(50%);
    }
    50% {
        transform: translateX(25%);
    }
}

.bounceRight {
    animation-name: bounce-right;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes interval-bounce-right {
    0%, 25% {
        transform: translateX(0);
    }
    10%, 20% {
        transform: translateX(50%);
    }
    15% {
        transform: translateX(25%);
    }
}

.intervalBounceRight {
    animation-name: interval-bounce-right;
    animation-duration: 6s;
    animation-iteration-count: infinite;
}

@keyframes bounce-up {
    0% {
        transform: translateY(0);
    }
    5%, 75% {
        transform: translateY(-50%);
    }
    50% {
        transform: translateY(-25%);
    }
}

.bounceUp {
    animation-name: bounce-up;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes bounce-down {
    0% {
        transform: translateY(0);
    }
    5%, 75% {
        transform: translateY(50%);
    }
    50% {
        transform: translateY(25%);
    }
}

.bounceDown {
    animation-name: bounce-down;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.5);
    }
}

.pulsate {
    animation-name: pulsate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.animationSpin {
    animation: fa-spin 2s infinite linear;
}
.animationPulse {
    animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.animationWobble {
    animation: wobble 0.5s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes wobble {
    12.5%, 62.5% {
        transform: rotate(-10deg);
    }
    37.5%, 87.5% {
        transform: rotate(10deg);
    }
}

.animationJiggle1 {
    animation-name: jiggle1;
    animation-iteration-count: infinite;
	transform-origin: 50% 10%;
}

.animationJiggle2 {
    animation-name: jiggle2;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    transform-origin: 30% 5%;
}

@keyframes jiggle1 {
    0% {
        transform: rotate(-1deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(1.5deg);
        animation-timing-function: ease-out;
    }
}

@keyframes jiggle2 {
    0% {
        transform: rotate(1deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(-1.5deg);
        animation-timing-function: ease-out;
    }
}
