
@import '~lessVars';

.editor-book-fill-view {
	background-color: var(--background-primary);
	color: var(--font-primary);
	display: flex;
	flex-direction: column;

	@media @desktop {
		border-radius: 24px;
		padding: 24px 32px;
	}

	@media @mobile {
		height: var(--app-height, 100vh);
		width: 100%;
	}

	@media @mobileNotSmall {
		padding: 24px 16px 32px;
	}

	@media @mobileSmall {
		padding: 12px 16px 8px;
	}

	.editor-book-fill-view-header {
		align-items: center;
		display: flex;
		justify-content: space-between;

		@media @mobileNotSmall {
			padding-bottom: 24px;
		}

		@media @mobileSmall {
			padding-bottom: 12px;
		}

		.editor-book-fill-view-header-step {
			background-color: var(--neutral3-background-fill);
			border-radius: 12px;
			color: var(--font-primary);
			font-family: @fontFamilyDemiBold;
			font-weight: @fontWeightDemiBold;
			padding: 4px 12px;

			@media @desktop {
				font-size: @fontSize_S_New;
			}

			@media @mobileNotSmall {
				font-size: @fontSize_XS_New;
			}

			@media @mobileSmall {
				font-size: @fontSize_XXS2_New;
			}
		}

		.icon-component {
			color: var(--icon-default);
			cursor: pointer;

			@media @mobile {
				font-size: @fontSize_M_New;
			}
		}
	}

	.editor-book-fill-view-body {
		display: flex;
		flex-direction: column;

		@media @desktop {
			margin: 48px 0;
			row-gap: 48px;
		}

		@media @mobile {
			height: 100%;
			justify-content: space-evenly;
			padding-top: 16px;
		}

		@media @mobileNotSmall {
			row-gap: 16px;
		}

		@media @mobileSmall {
			row-gap: 8px;
		}

		.editor-book-fill-view-body-header {
			display: flex;
			font-family: @fontFamilyBold;
			font-weight: @fontWeightBold;
			justify-content: center;
			text-align: center;

			@media @desktop {
				font-size: @fontSize_XXXL_New;
			}

			@media @mobileNotSmall {
				font-size: @fontSize_XL_New;
			}

			@media @mobileSmall {
				font-size: @fontSize_M_New;
			}
		}

		.editor-book-fill-view-pages-container {
			align-items: center;
			display: flex;
			flex-direction: column;

			@media @desktop {
				margin: 24px 0;
				row-gap: 16px;
			}

			@media @mobile {
				padding-top: 16px;
				row-gap: 12px;
			}

			.editor-book-fill-view-pages-selected-header {
				font-family: @fontFamilyDemiBold;
				font-weight: @fontWeightDemiBold;

				@media @desktop {
					font-size: @fontSize_L_New;
				}

				@media @mobileNotSmall {
					font-size: @fontSize_S2_New;
					margin-bottom: 12px;
				}

				@media @mobileSmall {
					font-size: @fontSize_XS2_New;
					margin-bottom: 6px;
				}

				.editor-book-fill-view-pages-selected-header-value {
					color: var(--accent1);
					font-family: @fontFamilyBold;
					font-weight: @fontWeightBold;
				}
			}

			.editor-book-fill-view-pages-selected-per-page {
				font-family: @fontFamilyRegular;
				font-weight: @fontWeightRegular;

				@media @mobile {
					margin: auto 0;
				}

				@media @mobileNotSmall {
					font-size: @fontSize_S_New;
					margin-top: 12px;
				}

				@media @mobileSmall {
					font-size: @fontSize_XS_New;
					margin-top: 6px;
				}
			}

			.editor-book-fill-view-pages-selected-image {
				@media @desktop {
					max-height: 256px;
				}

				@media @mobile {
					margin: auto 0;
				}

				@media @mobileNotSmall {
					max-height: 160px;
				}

				@media @mobileSmall {
					max-height: 90px;
				}
			}
		}

		.editor-book-fill-view-pages-slider-container {
			@media @desktop {
				column-gap: 16px;
				display: flex;
			}

			@media @mobile {
				display: grid;
				grid-template: auto / auto auto;
				grid-template-areas: 'input-slider input-slider' 'icon-slider-left icon-slider-right';
				row-gap: 22px;
			}

			@media @mobileNotSmall {
				padding: 32px 0;
			}

			@media @mobileSmall {
				padding: 12px 0;
			}

			.input-slider-component {
				@media @desktop {
					flex-grow: 1;
				}

				@media @mobile {
					grid-area: input-slider;
					margin: 0 18px;
				}
			}

			.editor-book-fill-view-pages-slider-icon-container {
				align-items: center;
				display: flex;
				flex-direction: column;

				@media @mobile {
					width: max-content;

					&:nth-child(1) {
						grid-area: icon-slider-left;
						margin-right: auto;
					}

					&:nth-child(3) {
						grid-area: icon-slider-right;
						margin-left: auto;
					}
				}

				.icon-component {
					@media @desktop {
						font-size: @fontSize_XXL_New;
					}

					@media @mobile {
						font-size: @fontSize_M_New;
					}
				}

				.editor-book-fill-view-pages-slider-icon-text {
					font-family: @fontFamilyDemiBold;
					font-weight: @fontWeightDemiBold;

					@media @desktop {
						font-size: @fontSize_XS_New;
					}

					@media @mobile {
						font-size: @fontSize_XXS2_New;
					}
				}
			}
		}

		.editor-book-fill-view-margins-container {
			@media @desktop {
				column-gap: 24px;
				display: flex;
				justify-content: center;
				margin: 76px 0;
			}

			.editor-book-fill-view-margin {
				align-items: center;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				justify-content: center;
				row-gap: 24px;

				@media @desktop {
					--border-color: var(--neutral1-borders-and-stroke);
					--border-width: 2px;
					border-radius: 24px;
					border: var(--border-width) solid var(--border-color);
					padding: calc(60px - var(--border-width)) calc(24px - var(--border-width));

					&.editor-book-fill-view-margin-selected,
					&:hover {
						--border-color: var(--black1);
						--border-width: 4px;
					}
				}

				&:not(.editor-book-fill-view-margin-selected) {
					.editor-book-fill-view-margin-type {
						font-family: @fontFamilyDemiBold;
						font-weight: @fontWeightDemiBold;
					}
				}

				&.editor-book-fill-view-margin-selected,
				&:hover {
					.editor-book-fill-view-margin-type {
						font-family: @fontFamilyBold;
						font-weight: @fontWeightBold;
					}
				}

				.editor-book-fill-view-margin-image {
					width: 100%;

					@media @desktop {
						max-height: 156px;
					}
				}

				.editor-book-fill-view-margin-type {
					font-size: @fontSize_S_New;
					text-align: center;
				}
			}

			.editor-book-fill-view-margins-buttons-toggle-container {
				display: flex;
				justify-content: center;
				margin-top: auto;
				padding: 16px 0;

				.editor-book-fill-view-margins-buttons-toggle {
					background-color: var(--neutral3-background-fill);
					border-radius: 8px;
					column-gap: 4px;
					display: flex;
					padding: 4px;
					width: max-content;

					.editor-book-fill-view-margins-button-toggle {
						background-color: transparent;
						border: none;
						color: var(--font-primary);
						font-family: @fontFamilyRegular;
						font-size: @fontSize_S_New;
						font-weight: @fontWeightRegular;
						padding: 4px 16px;

						&.editor-book-fill-view-margins-button-toggle-selected {
							background-color: var(--background-primary);
							border-radius: 4px;
							font-family: @fontFamilyBold;
							font-weight: @fontWeightBold;
						}
					}
				}
			}
		}
	}

	.divider {
		border-color: var(--neutral1-borders-and-stroke);
		margin-top: 0;
	}

	.editor-book-fill-view-footer {
		display: flex;

		.price-view {
			font-family: @fontFamilyBold;
			font-size: @fontSize_XXXXL_New;
			font-weight: @fontWeightBold;
		}

		.editor-book-fill-view-footer-buttons {
			display: flex;

			&:not(.editor-book-fill-view-footer-buttons-full-width) {
				margin-left: auto;
			}

			&.editor-book-fill-view-footer-buttons-full-width {
				width: 100%;
			}

			@media @desktop {
				column-gap: 12px;
			}

			@media @mobile {
				column-gap: 8px;

				&.editor-book-fill-view-footer-buttons-last-step {
					padding-top: 28px;
				}
			}
		}
	}
}
