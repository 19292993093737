html,
a,
area {
	// Prevent iOS link flashing
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

area {
	cursor: pointer;
}

html,
body {
	height: 100%;
	color: @black;
}

body {
	padding: 0;
	margin: 0;
	min-height: 100%;
	font: @font;
	text-transform: @fontTransform;
	background: @background;

	@media @mobile {
		background: @mobile_background;
	}
}

labelName {
	text-transform: none;
}

div,
ul,
li,
button,
textarea {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

button {
	text-transform: @fontTransform;
}

input,
textarea,
select {
	color: @black;

	@media @mobile {
		/* Set font size in form fields to 16px, so that iPhone does not auto zoom */
		font-size: @fontSize_S;
	}
}

input:focus,
select:focus,
textarea:focus {
	outline: none;
}

img {
	-webkit-touch-callout: none; // disable 'save image as' dialog for iOS devices
}

h1,
h2,
h3 {
	padding: 0;
	margin: 0;
}

h2 {
	color: @header_text;
	font: @header_font;
}

span.warning {
	color: red;
}

span.asteriks {
	font-size: 0.5em;
	vertical-align: super;
}

span.link {
	text-decoration: underline;
	cursor: pointer;
}

span.exclamation:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f06a";
	display: inline-block;
}

.hide {
	display: none !important;
}

input.invalid,
select.invalid {
	border: 1px solid red !important;
	box-shadow: 0 0 5px red;
}

input.maxlength {
	border: 1px solid orangered !important;
	box-shadow: 0 0 5px orangered;
}

.noUserSelect {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

#uploadButton {
	display: none !important;
}

.responsive {
	@media @desktop {
		width: 50%;
	}

	@media @mobile {
		width: 100%;
	}
}

.responsive-left {
	float: left;

	@media @desktop {
		padding-right: 8px;
	}
}

.responsive-right {
	float: right;

	@media @desktop {
		padding-left: 8px;
	}
}

#webapp.no-scroll {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* Container for application content, centered in screen */
.container {
	width: 100%;
	padding: 0;
	border: 0;
	margin: 0 auto;
}

.container:not(#showcase) {
	max-width: @maxWidthDesktop;
}

#workspace {
	position: relative;
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	border: 0;

	@media @desktop {
		background: @workspace_bg;
	}

	@media @mobile {
		background: @mobile_background;
	}
}

.subNavigation.fixed~#workspace {
	@media @desktop {
		margin-top: @subNavBarHeightDesktop;
	}

	@media @mobile {
		margin-top: @subNavBarHeightMobile;
	}
}

#body {
	float: left;
	width: 100%;
	position: relative;
}

.toolbar {
	list-style: none;
	margin: 0;
	padding: 0;
	display: table;
}

.webPushSoftAsk {
	display: none;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: @dialogZindex + 1;
	text-align: center;
}

/**
 *  Forms
 */

.form,
.form .formrow {
	width: 100%;
	float: left;
}

.form .formlabel,
.form .forminput {
	float: left;
	text-align: left;
	padding: 5px;

	@media @mobile {
		text-align: center;
	}
}

.form-fluid .formlabel {
	width: 25%;

	@media @mobile {
		width: 100%;
	}
}

.form-fluid .forminput {
	width: 75%;

	@media @mobile {
		width: 100%;
	}
}

.form .forminput input,
.form .forminput textarea {
	text-align: left;

	@media @mobile {
		text-align: center;
	}
}

/**
 * 	Tables
 */

.table {
	width: 100%;
}

.table>.tr {
	float: left;
	width: 100%;
	margin: 5px 0;
}

.table .td {
	float: left;
}

.table .td.align-right {
	text-align: right;
}

.table .td1 {
	width: 100%;
}

.table .td2 {
	width: 50%;
}

.table .td3 {
	width: 33%;
}

.table .td3x2 {
	width: 66%;
}

.table .td4 {
	width: 25%;
}

.table .td4x3 {
	width: 75%;
}

.table .td5 {
	width: 20%;
}

.table .td8 {
	width: 12.5%;
}

.table .td10 {
	width: 10%;
}

.table .td10x3 {
	width: 30%;
}

.table.responsive .td {
	@media @size480 {
		width: 100% !important;
	}
}

.table.responsive .td:first-child {
	vertical-align: top;
	padding-top: 12px;
}

.table .tr.subtotal_row {
	font-style: italic;
}

.table .tr.subtotal_row .td:last-child,
.table .tr.total_row .td:last-child {
	border-top: 1px dashed #CBCBCB;
}

.table .tr.total_row {
	font-weight: 700;
}

.pagecontainer {
	float: left;
}

.pagethumb {
	position: relative;
	display: block;
}

.pagethumb .livecanvas {
	position: absolute;
}

.pagecontainer .thumbtools .closeicon {
	cursor: pointer;
	width: 42px;
	height: 42px;
	position: relative;
	text-align: center;
}

.pagecontainer .left.spread .thumbtools .closeicon,
.pagecontainer .right:not(.spread) .thumbtools .closeicon {
	float: right;
	left: 21px;
}

.pagecontainer .right.spread .thumbtools .closeicon,
.pagecontainer .left:not(.spread) .thumbtools .closeicon {
	float: left;
	right: 21px;
}

.desktopOnly {
	@media @mobile {
		display: none !important;
	}
}

.mobileOnly {
	@media @desktop {
		display: none !important;
	}
}
