
@import '~lessVars';

.editor-build-options-view {
	background-color: var(--background-primary);
	border-radius: 24px;
	color: var(--font-primary);
	display: flex;
	flex-direction: column;

	@media @desktop {
		padding: 24px 32px;
		row-gap: 40px;
	}

	@media @mobile {
		padding: 16px 20px;
		row-gap: 24px;
	}

	.editor-build-options-view-header {
		align-items: center;
		display: flex;
		justify-content: space-between;

		.editor-build-options-view-header-title {
			font-weight: @fontWeightBold;
			font-family: @fontFamilyBold;

			@media @desktop {
				font-size: @fontSize_L_New;
			}

			@media @mobile {
				font-size: @fontSize_M_New;
			}
		}

		@media @desktop {
			.icon-component {
				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.editor-build-options-view-body {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media @desktop {
			row-gap: 40px;
		}

		@media @mobile {
			row-gap: 24px;
		}

		.editor-build-options-view-body-header {
			font-family: @fontFamilyDemiBold;
			font-weight: @fontWeightDemiBold;

			@media @desktop {
				display: flex;
				font-size: @fontSize_L_New;
				justify-content: center;
			}

			@media @mobile {
				font-size: @fontSize_S_New;
			}
		}

		.editor-build-options-view-options-container {
			display: flex;

			@media @desktop {
				column-gap: 16px;
			}

			@media @mobile {
				column-gap: 12px;
			}

			.editor-build-options-view-option {
				align-items: center;
				border-radius: 16px;
				border-style: solid;
				border-width: var(--border-width);
				cursor: pointer;
				display: flex;
				flex-direction: column;
				flex-basis: 50%;
				justify-content: center;

				@media @desktop {
					--border-width: 2px;
					padding: calc(73px + 4px - var(--border-width)) 0;
					row-gap: 24px;

					&.editor-build-options-view-option-selected,
					&:hover {
						--border-width: 4px;
					}
				}

				@media @mobile {
					--border-width: 1px;
					padding: calc(37px + 2px - var(--border-width)) 0;
					row-gap: 12px;

					&.editor-build-options-view-option-selected,
					&:active {
						--border-width: 2px;
					}
				}

				&:not(.editor-build-options-view-option-selected):not(:hover):not(:active) {
					border-color: var(--neutral1-borders-and-stroke);

					@media @mobile {
						font-family: @fontFamilyRegular;
						font-weight: @fontWeightRegular;
					}

					@media @desktop {
						font-family: @fontFamilyDemiBold;
						font-weight: @fontWeightDemiBold;
					}
				}

				.editor-build-options-view-option-selected() {
					border-color: var(--black1);
					font-family: @fontFamilyBold;
					font-weight: @fontWeightBold;
				}

				&.editor-build-options-view-option-selected {
					.editor-build-options-view-option-selected();
				}

				@media @desktop {
					&:hover {
						.editor-build-options-view-option-selected();
					}
				}

				@media @mobile {
					&:active {
						.editor-build-options-view-option-selected();
					}
				}

				.editor-build-options-view-option-image {
					@media @desktop {
						max-height: 178px;
					}

					@media @mobile {
						max-height: 50px;
					}
				}

				.editor-build-options-view-option-type {
					text-align: center;

					@media @desktop {
						font-size: @fontSize_S_New;
					}

					@media @desktop {
						font-size: @fontSize_XS2_New;
					}
				}
			}
		}
	}

	.editor-build-options-view-footer {
		display: flex;
		flex-direction: column;

		@media @desktop {
			.divider {
				border-color: var(--neutral1-borders-and-stroke);
				margin-bottom: 16px;
				margin-top: 0;
			}
		}

		@media @mobile {
			margin-top: 8px;
		}

		.editor-build-options-view-footer-buttons {
			display: flex;

			@media @desktop {
				column-gap: 16px;

				.divider {
					border-color: var(--neutral1-borders-and-stroke);
					margin-bottom: 16px;
					margin-top: 0;
				}
			}

			@media @mobile {
				column-gap: 8px;
				margin-top: 8px;
			}
		}
	}
}
