
@import '~lessVars';

.introText {
	float: left;
	width: 100%;
}
.list {
    float: left;
	margin: 15px 0 0;
	font-size: @fontSize_XS;
	width: 100%;
	padding: 0;
	display: grid;
	row-gap: 20px;
	column-gap: 20px;
	grid-template-columns: 1fr;
}
.listItem {
	list-style: none;
	float: left;
	cursor: pointer;
	position: relative;
	align-items: center;
	background-color: #e5e3de;
	border-radius: 3px;
	padding: 10px;
	display: grid;
	grid-template-columns: 2fr 1fr;

	@media @mobile {
		grid-column-gap: 20px;
	}
	@media @desktop {
		grid-column-gap: 30px;
	}

	color: #282828;
	box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);

	& when(@brand="Hema") {
		box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
		color: #323232;
		text-transform: lowercase;
	}

	/* Hack (for the experiment) to make first item look smaller,
	this would have to be converted to something more structural */
	&:first-child {
		:deep(.imageNode) {
			img {
				max-width: 80%;
			}
		}
	}

	.imageNode,
	.textNode {
		float: left;
	}

	.imageNode {
		text-align: center;
		margin: 0;
		padding: 0;
		background: none;
		position: relative;

		.productImage {
			max-width: 100%;
			max-height: 100px;
			display: inline-block;
		}

		.mostSelected {
			display: flex;
			align-items: center;
			position: absolute;
			top: -20px;
			right: -10px;
			background-color: #f8b840;
			color: white;

			@media @mobile {
				width: 50px;
				height: 50px;
				font-size: @fontSize_XXS1;
			}
			@media @desktop {
				width: 70px;
				height: 70px;
				font-size: @fontSize_XXS3;
			}
		}
	}
	.textNode {
		.textWrapper {
			color: @black;

			& when(@brand="Hema") {
				color: #323232;
				text-transform: none;
			}

			.itemHeader {
				padding: 0 0 5px;
				clear: both;
				display: block;

				font-family: @fontFamilyBold;
				font-weight: @fontWeightBold;
				font-size: @fontSize_S;

				& when(@brand="Hema") {
					font-family: @fontFamilyDemiBold;
					font-weight: @fontWeightDemiBold;
					font-size: @fontSize_XS2;
				}
			}
		}

		.priceWrapper {
			float: left;
			width: 100%;
			text-align: right;

			.priceValue {
				display: block;
				clear: both;
				color: #393939;
				font-size: @fontSize_XS2;

				& when(@brand="Hema") {
					font-size: @fontSize_M;
					font-family: @fontFamilyBold;
					font-weight: @fontWeightBold;
				}
			}
		}
	}
}
