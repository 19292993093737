
@import '~lessVars';

.button-component {
	align-items: center;
	border: 0;
	border-radius: 8px;
	display: flex;
	font-family: var(--font-family-bold);
	font-weight: var(--font-weight-bold);
	justify-content: center;
	line-height: 100%;
	padding: 10px 12px;
	text-transform: var(--font-transform);

	&:not(.button-component-disabled) {
		cursor: pointer;
	}

	&.primary {
		background-color: var(--primary1);
		color: var(--white1);

		@media @desktop {
			&:hover:not(.button-component-disabled) {
				background-color: var(--primary2);
			}
		}

		@media @mobile {
			&:active:not(.button-component-disabled) {
				background-color: var(--primary2);
			}
		}

		&.button-component-disabled {
			background-color: var(--black2-disabled);
			color: var(--white1);
		}
	}

	&.secondary {
		background-color: var(--neutral3-background-fill);
		color: var(--font-on-background-fill);

		@media @desktop {
			&:hover:not(.button-component-disabled) {
				background-color: var(--neutral1-borders-and-stroke);
			}
		}

		@media @mobile {
			&:active:not(.button-component-disabled) {
				background-color: var(--neutral1-borders-and-stroke);
			}
		}

		&.button-component-disabled {
			color: var(--neutral4-grey);
		}
	}

	&.tertiary {
		background-color: transparent;
		color: var(--primary1);
	}

	&.quaternary {
		background-color: var(--neutral6-grey-fill);
		color: var(--font-on-background-fill);

		&.button-component-disabled {
			border: 1px solid var(--neutral1-borders-and-stroke);
			color: var(--font-neutral-grey1);
		}
	}
}
