
@import '~lessVars';

.input-slider-component {
	@handle-size: 24px;
	align-items: center;
	display: flex;
	justify-content: center;

	@media @desktop {
		column-gap: 8px;
	}

	@media @mobile {
		column-gap: 16px;
	}

	.input-slider {
		background-color: var(--input-slider-background);
		border-radius: 100px;
		cursor: pointer;
		display: grid;
		flex-grow: 1;
		grid-template-areas: 'element';
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		height: var(--track-height);

		.input-slider-handle,
		.input-slider-input,
		.input-slider-steps,
		.input-slider-track {
			grid-area: element;
		}

		.input-slider-handle {
			align-items: center;
			border-radius: 50%;
			color: var(--white1);
			display: flex;
			justify-content: center;
			height: @handle-size;
			pointer-events: none;
			user-select: none;
			width: @handle-size;

			.icon-component {
				font-size: @fontSize_S_New;
			}
		}

		.input-slider-input {
			color: transparent;
			appearance: none;
			background: transparent;
			cursor: pointer;
			height: var(--track-height);
			margin: 0;

			.input-slider-input-thumb-mixin() {
				appearance: none;
				background: transparent;
				border-radius: 100%;
				height: @handle-size;
				width: @handle-size;
			}

			&::-moz-range-thumb {
				.input-slider-input-thumb-mixin();
			}

			&::-ms-thumb {
				.input-slider-input-thumb-mixin();
			}

			&::-webkit-slider-thumb {
				.input-slider-input-thumb-mixin();
			}
		}

		.input-slider-steps {
			display: flex;
			height: var(--track-height);
			justify-content: space-between;
			pointer-events: none;

			.input-slider-step-container {
				display: flex;
				justify-content: center;
				width: @handle-size;

				&:first-child {
					.input-slider-step {
						margin-right: auto;
					}
				}

				&:last-child {
					.input-slider-step {
						margin-left: auto;
					}
				}

				.input-slider-step {
					background-color: var(--neutral5-slider-step);
					border-radius: 100%;
					height: var(--track-height);
					width: var(--track-height);
				}
			}
		}

		.input-slider-track {
			pointer-events: none;
			margin: 0 auto;
			height: var(--track-height);
			width: calc(100% - @handle-size);
		}
	}

	.input-slider-decrease-button,
	.input-slider-increase-button {
		border-radius: 8px;
		padding: 6px;

		&:not(.icon-component-disabled) {
			cursor: pointer;
		}
	}

	.input-slider-value {
		color: var(--font-primary);
		background-color: var(--background-tertiary);
		border: 1px solid var(--neutral1-borders-and-stroke);
		border-radius: 8px;
		box-sizing: border-box;
		min-height: 36px;
		padding: 6px 8px;
		position: relative;
		width: 44px;

		&.input-slider-value-no-editable {
			.input-slider-value-span {
				cursor: default;
			}
		}

		&:not(.input-slider-value-no-editable) {
			.input-slider-value-span {
				pointer-events: none;
				visibility: hidden;
			}
		}

		.input-slider-value-input,
		.input-slider-value-span {
			align-items: center;
			box-sizing: border-box;
			display: flex;
			font-family: @fontFamilyDemiBold;
			font-size: @fontSize_S_New;
			font-weight: @fontWeightDemiBold;
			justify-content: center;
			height: 100%;
			text-align: center;
			width: 100%;
		}

		.input-slider-value-input {
			appearance: textfield;
			background-color: transparent;
			border: none;
			color: inherit;
			left: 0;
			outline: none;
			position: absolute;
			top: 0;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				appearance: none;
				margin: 0;
			}
		}
	}
}
