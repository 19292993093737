@import '~lessVars';

.viewWrapper {
	float: left;
	width: 100%;

	@media @desktop {
		margin-bottom: 60px;
		padding-left: @viewWrapperPaddingEdgeDesktop;
		padding-right: @viewWrapperPaddingEdgeDesktop;
		padding-top: @viewWrapperPaddingTopDesktop;
		padding-bottom: @viewWrapperPaddingBottomDesktop;
        margin-top: 10px;
	}

	@media @mobile {
		padding-left: @viewWrapperPaddingEdgeMobile;
		padding-right: @viewWrapperPaddingEdgeMobile;
		padding-top: @viewWrapperPaddingTopMobile;
		padding-bottom: @viewWrapperPaddingBottomMobile;
    }
}
.widthLimiter {
	height: inherit;
	max-width: @maxWidthDesktop;
	margin: 0 auto;
}
