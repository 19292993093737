
@import '~lessVars';
@import '../../css/components/dialog.less';

.dialog {
	&.chrome {
		width: 75%;
		max-width: 800px;
		margin: auto;

		@media @desktop {
			padding-left: 40px;
			padding-right: 40px;
		}
		@media @mobile {
			width: 90%;
		}
	}

	.title {
		@media @desktop {
			& when(@brand="Hema") {
				font-size: @fontSize_L4;
			}
		}
	}
}
