
@import '../../css/components/picker.less';

.pickerItem {
	text-align: center;

    .imageNode {
		display: inline-block;

        img {
			max-width: 100%;
        }
	}

	.textNode {
		display: inline-block;
		width: 100%;
	}
}
