@import '~lessVars';

.loginForm,
.check__text__wrapper {
	margin-top: 25px;

	@media @mobile {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}

.signup__dialog .check__text__wrapper {
	@media @mobile {
		display: none;
	}
}

.dialogTopLinks {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.dialogTopLink {
		font-size: @fontSize_XS;
		padding: 0;
		cursor: pointer;
		display: flex;
		align-items: center;

		.icon,
		.text {
			display: inline-block;
			height: 30px;
			line-height: 24px;
		}
	}
}

.or {
	margin-top: 20px;
	text-align: center;
	width: 100%;
	float: left;

	hr {
		width: 100%;
		height: 1px;
		border: 0;
		border-top-width: 1px;
		border-style: solid;
		padding: 0;
		border-color: @dialog_body_hr;
	}

	span {
		background: @dialog_body_bg;
		padding: 0 20px;
		position: relative;
		top: -20px;
	}
}

.spacer {
	width: 100%;
	display: inline-block;
}

.input-group {
	display: table;
	position: relative;
	margin-bottom: 15px;
	width: 100%;

	label {
		display: inline-block;
		margin-bottom: 6px;
		font-size: @fontSize_XS1;
	}

	input {
		width: 100%;
		box-sizing: border-box;
		display: table-cell;
		height: 40px;
		padding: 5px 10px;
		font-family: @fontFamilyRegular;
		font-weight: @fontWeightRegular;
		font-size: @fontSize_XS1;
		line-height: 17px;
		border: 1px solid #c6c6c6;
		background-color: #fff;
		border-radius: 2px;
		color: #323232;
	}
}

.line {
	position: relative;
	padding-right: 20px;
}

.line:after {
	content: '';
	position: absolute;
	right: 0;
	border-right: 0.5px solid #cfc7c0;
	top: 1%;
	bottom: 10%;

	@media @mobile {
		display: none;
	}
}

.login__dialog,
.signup__dialog {
	display: flex;

	@media @desktop {
		padding-top: 10px;
	}

	@media @mobile {
		flex-direction: column;
		font-size: @fontSize_XS;
	}
}

.new__to__text,
.description__text {
	font-size: @fontSize_M3;

	@media @mobile {
		font-size: @fontSize_S;
	}
}

.new__to__text {
	color: @brandcolor1;

	& when(@brand="Hema") {
		color: #12CDD4;
	}
}

.description__text {
	color: @brandcolor1;

	& when(@brand="Hema") {
		color: #9ac122;
	}
}

.check__text {
	display: flex;
	align-items: center;

	.check {
		font-size: 30px;
		color: #9ac122;
		font-weight: 900;
		padding-right: 10px;

		@media @mobile {
			font-size: 20px;
		}
	}
}

.pull__up {
	padding-bottom: 5px;
}

.first {
	width: 50%;

	@media @mobile {
		width: 100%;
	}
}

.second {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 20px;

	@media @mobile {
		width: 100%;
		padding-left: 0;
		padding-top: 20px;
	}
}

.togglePassword {
	position: absolute;
	bottom: 7px;
	right: 7px;
	height: 26px;
	border: 1px solid #c6c6c6;
	border-radius: 2px;
	color: #9b9b9b;
	background-color: #fff;
	appearance: none;
	outline: 0;
	box-shadow: 0;
	font-family: @fontFamilyRegular;
	font-weight: @fontWeightRegular;
	cursor: pointer;
	padding: 0 15px;
	z-index: 3;

	& when(@brand="Hema") {
		text-transform: lowercase;

		&.show {
			border-color: #5dc5e3;
			background-color: #5dc5e3;
			color: white;
		}
	}
}

.subHeader {
	& when(@brand="Hema") {
		text-transform: none;
	}
}
