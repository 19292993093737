
@import '~lessVars';
@import '~root/css/components/input.less';

.offeringOptionsView {
	float: left;
	width: 100%;

	.tr {
		display: flex;
		align-items: center;
		float: left;
		width: 100%;
		margin: 5px 0;

		@media @size480 {
			flex-wrap: wrap;
		}

		&.priceRow {
			margin-top: 15px;
		}
	}
	.td {
		float: left;

		&:first-child {
			width: 33%;
		}
		&:last-child {
			width: 66%;
		}

		@media @size480 {
			width: 100% !important;
		}
	}
}

.selectBox {
    margin: 2px 0;

    @media @mobile {
        width: 100%;
    }
    @media @desktop {
        width: 330px;
        max-width: 100%;
    }
}
.productPrice {
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	font-size: @fontSize_M4;
}
