
.imagesWrapper {
	position: relative;
	width: 300px;
	height: 150px;
}
.originalImage,
.copyImage {
	position: absolute;
	max-height: 150px;
	top: 0;
	left: 0;
}
.copyImage {
	animation-name: move;
	animation-delay: 1s;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}
.textWrapper {
	width: 100%;
	float: left;
	padding-top: 20px;
}

@keyframes move {
	0% {
		left: 0;
	}
	100% {
		left: 240px;
	}
}
