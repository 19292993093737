
@import '~lessVars';

.listItem {
    cursor: pointer;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 1.2em;
    min-height: 42px;
	font-family: @fontFamilyDemiBold;
	font-weight: @fontWeightDemiBold;
	border-bottom: 1px solid #e5e3de;
	border-right: 1px solid #e5e3de;

    &.suggested {
        font-size: 1.5em;
		grid-column-start: 1;
		grid-column-end: 3;
	}

	.inner {
		align-items: center;
		display: flex;
		padding: 20px;

		.flag {
			margin: auto 0;
			margin-right: 5px;
		}

		span {
			font-family: @fontFamilyDemiBold;
			font-weight: @fontWeightDemiBold;
			font-size: @fontSize_XS3;

			& when(@brand="Hema") {
				text-transform: none;
			}
		}
	}
}
