@import '~lessVars';

.modal {
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: @dialogZindex;
	text-align: center;
}

.lightModal {
	.modal;

	background: rgba(0, 0, 0, 0.2);
}

.dialog {
	background: @dialog_bg;
	margin: 0;
	position: fixed;
	top: 50%;
	left: 50%;
	font-size: 1em;
	border: @dialog_border;
	border-radius: @dialog_radius;

	&.chrome {
		padding: 20px;

		> .body:not(.noBodyPadding) {
			padding: 10px 0 20px;
		}
	}

	> .header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row-reverse;
		margin-bottom: 25px;
		float: left;
		width: 100%;
		position: relative;
		border-top-left-radius: @dialog_radius;
		border-top-right-radius: @dialog_radius;
		text-align: left;
		font-family: @fontFamilyBold;
		font-weight: @fontWeightBold;
		font-size: @fontSize_M;
		line-height: 100%;
		background: #fff;

		color: @black;

		& when(@brand="Hema") {
			color: #464646;
		}

		&.picker .title {
			color: @header_text;
			font: @header_font;
		}

		.closer {
			flex: 0 0 28px;
			width: 28px;
			height: 28px;
			border-radius: 14px;
			background: #9b9b9b;
			text-align: center;
			color: #fff;
			cursor: pointer;

			i {
				line-height: 28px;
				font-size: 18px;
				font-weight: bold;
			}
		}
	}

	> .body {
		font-size: @fontSize_XS1;
		text-align: @dialog_align;
		float: left;
		margin: 0;
		overflow-x: hidden;
		overflow-y: auto;
		width: 100%;
		color: @dialog_body_text;
		background: @dialog_body_bg;

		& when(@brand="Hema") {
			line-height: 23px;
			font-weight: 200;
		}

		input:not(.input-slider-input),
		textarea,
		select {
			padding: @dialog_input_padding;
			border: @dialog_input_border;
			width: 100%;
			margin: 2px 0;
			text-align: inherit;
			border-radius: @dialog_input_radius;
			box-sizing: border-box;
		}
	}
}
