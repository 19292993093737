
@import '../../css/components/animation.less';

.coverViewWrapper {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
}
.imageWrap {
	height: inherit;
	width: 100%;
}
.placeholderImg {
	width: 100%;
	border: 1px solid #ccc;
}
.statusIcon {
	position: absolute;
	left: 0;
	text-align: center;
	width: 100%;
	top: 35%;
}
.retryWrap {
	.retry {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		text-align: center;
		width: 100%;
		cursor: pointer;
		font-size: 0.5em;
		left: 0;
		bottom: 10px;

		i {
			margin-bottom: auto;
		}
	}
}
.imageWrap {
	position: relative;
}
img {
	max-width: 100%;
	max-height: 100%;
}
.loaderWrap {
	width: 64px;
	height: 64px;
	position: absolute;
	bottom: ~"calc(50% - 32px)";
	left: ~"calc(50% - 32px)";
}
