
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/loadbar.less';

.viewWrapper {
    float: left;
    width: 100%;
    text-align: left;
}
.itemWrapper {
    float: left;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    .inputLabel {
        float: left;
        width: 100%;
    }
    .inputField {
        float: left;
        width: 100%;
        padding: 5px;
        border: 1px solid #ccc;
        margin: 2px 0;
        text-align: inherit;
        border-radius: 0;
        box-sizing: border-box;
    }
}
.errorBox {
    float: left;
    width: 100%;
    color: red;
    overflow: hidden;
    overflow-wrap: break-word;
    padding-bottom: 5px;
}
