@font-face {
	font-family: 'Label Icons';
	font-style: normal;
	font-weight: 400;
	src: url('./FastEditor-Icons.eot') format('embedded-opentype'),
		url('./FastEditor-Icons.woff') format('woff'),
		url('./FastEditor-Icons.ttf') format('truetype'),
		url('./FastEditor-Icons.svg') format('svg');
}

.label-icon {
	display: inline-block;
	font-family: 'Label Icons';
	font-size: 24px;
	-webkit-font-smoothing: none;
	-moz-font-smoothing: none;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	text-transform: none;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
}
