
@import '~lessVars';
@import '~root/css/components/button.less';

.smart-enhancement-view {
	display: flex;
	flex-direction: column;
	max-width: calc((var(--max-image-width) * 2) + 1px);
	row-gap: 12px;

	@media @desktop {
		--max-image-width: 198px;
	}

	@media @mobile {
		--max-image-width: 157px;
	}

	.smart-enhancement-view-images {
		column-gap: 1px;
		display: flex;

		.smart-enhancement-view-image-container {
			--placeholder-margin: 4px;
			display: grid;
			flex-basis: 50%;
			grid-template-areas: 'element';
			height: var(--max-image-width);
			overflow: hidden;

			&:nth-child(1) {
				.smart-enhancement-view-image-placeholder {
					margin-left: var(--placeholder-margin);
					margin-right: auto;
				}
			}

			&:nth-child(2) {
				.smart-enhancement-view-image-placeholder {
					margin-left: auto;
					margin-right: var(--placeholder-margin);
				}
			}

			.smart-enhancement-view-image,
			.smart-enhancement-view-image-placeholder {
				grid-area: element;
			}

			.smart-enhancement-view-image {
				width: 100%;
			}

			.smart-enhancement-view-image-placeholder {
				background-color: var(--accent1);
				border-radius: 2px;
				color: var(--white1);
				font-family: @fontFamilyBold;
				font-size: @fontSize_XXS_New;
				font-weight: @fontWeightBold;
				margin-bottom: auto;
				margin-top: var(--placeholder-margin);
				padding: 2px;
			}
		}
	}

	.smart-enhancement-view-description {
		font-family: @fontFamilyRegular;
		font-weight: @fontWeightRegular;

		@media @desktop {
			font-size: @fontSize_M_New;
		}

		@media @mobile {
			font-size: @fontSize_S_New;
		}
	}

	.smart-enhancement-view-toggle {
		:deep(.switch-box-component) {
			label {
				font-size: @fontSize_XS3_New;
			}
		}
	}
}

