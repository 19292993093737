
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/dialog.less';

.modal {
	align-items: center;
	display: flex;
	justify-content: center;
}

.dialog {
	left: initial;
	position: initial;
	top: initial;

	&.auth {
		&.chrome {
			margin: auto;
			max-width: 800px;
			width: 75%;

			@media @desktop {
				padding-left: 40px;
				padding-right: 40px;
			}
			@media @mobile {
				width: 90%;
			}
		}

		> .header {
			.title {
				@media @desktop {
					& when(@brand="Hema") {
						font-size: @fontSize_L4;
					}
				}
			}
		}
	}

	> .body {
		.bodyBox {
			float: left;
			width: 100%;

			& when(@brand="Hema") {
				text-transform: none;
			}

			pre {
				margin: 0;
				white-space: pre-wrap;
				word-wrap: break-word;
				font-family: inherit;
			}
		}

		hr {
			height: 1px;
			border: 0;
			border-top-width: 1px;
			border-style: solid;
			padding: 0;
			width: 80%;
			border-color: @dialog_body_hr;
		}
		.bodyBox + .promptWrapper {
			margin-top: 10px;
		}

		.or {
			margin-top: 20px;
			text-align: center;
			width: 100%;
			float: left;

			hr {
				width: 100%;
			}

			span {
				background: @dialog_body_bg;
				padding: 0 20px;
				position: relative;
				top: -20px;
			}
		}
	}

	> .buttons {
		padding: @dialog_buttons_padding;
		clear: left;
		float: left;
		width: 100%;
		border-top: @dialog_buttons_bordertop;
		background: @dialog_buttons_bg;
		border-bottom-left-radius: @dialog_radius;
		border-bottom-right-radius: @dialog_radius;
		display: flex;
		justify-content: space-between;

		&.doubleButtons:not(.wrap) {
			.dialogButton {
				max-width: ~"calc(100% - 10px)";
			}
		}
		&.wrap {
			flex-wrap: wrap;

			.dialogButtonWrapper:nth-child(2) {
				margin-top: 20px;
			}
		}

		.dialogButtonWrapper {
			width: 100%;
			text-align: left;

			.dialogButton {
				width: 100%;
				height: 50px;
				padding: 0 20px;
				font-family: @fontFamilyBold;
				font-weight: @fontWeightBold;
				font-size: @fontSize_XS2;
				border-radius: 5px;
				cursor: pointer;
				border: 0;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
				line-height: 1em;

				background-color: #f0f0f0;
				color: #282828;

				& when(@brand="Hema") {
					text-transform: lowercase;
					background-color: #9b9b9b;
					color: #fff;
					font-family: @fontFamilyDemiBold;
					font-weight: @fontWeightDemiBold;
					font-size: @fontSize_XS1
				}
			}

			&:last-child {
				text-align: right;

				.dialogButton {
					background-color: @colorPalette2;

					& when(@brand="Printiki") {
						color: #fff;
					}
					& when(@brand="HelloPrint") {
						color: #fff;
					}
				}
			}
		}
		.imagebutton {
			&:not(:first-child) {
				padding-left: 5px;
			}
			&:not(:last-child) {
				padding-right: 5px;
			}
			img {
				max-width: 100%;
			}
		}
	}
}
