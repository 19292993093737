@import '~lessVars';
@import 'animation.less';

.button {
	margin: 0;
	cursor: pointer;
	text-align: center;
	display: inline-block;
}

.actionbutton {
	.button;

	float: none;
	display: inline-block;
	border: 0;
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	outline: none;
	border-radius: 5px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: #fff;

	background-color: var(--primary1);
	font-size: @fontSize_S;

	& when(@brand="Hema") {
		background-color: @colorPalette2;
		font-size: @fontSize_XS1;
		text-transform: lowercase;
		font-family: @fontFamilyDemiBold;
		font-weight: @fontWeightDemiBold;
	}

	& when(@brand="Agfa") {
		box-shadow: none;
	}

	@media @mobile {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media @desktop {
		padding-left: 16px;
		padding-right: 16px;
	}

	&.full {
		width: 100%;
	}

	&.fullDesktop {
		@media @desktop {
			min-width: 280px;
		}
	}

	&.fullMobile {
		@media @mobile {
			width: 100%;
		}
	}

	&.floatButton {
		position: fixed;
		bottom: 30px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: ~"calc(100% - 40px)";
		max-width: 420px;
	}

	&:not(.disabled):hover {
		color: #fff;
		background-color: var(--primary2);

		& when(@brand="Hema") {
			background: #E99800;
		}
	}

	&.disabled {
		cursor: not-allowed;
		color: #999999;
		text-shadow: none;
		opacity: 0.5;
	}

	&.altColor {
		& when(@brand="Hema") {
			background-color: #5dc5e3;
		}

		&:hover {
			& when(@brand="Hema") {
				background-color: #5dc5e3;
			}
		}
	}

	&.alt2Color {
		background-color: @colorPalette1;

		& when(@brand="Hema") {
			background-color: #E6E6E6;
		}
	}

	&.alt3Color {
		& when(@brand="Hema") {
			background-color: #84CB46;
		}
	}
}

.toolbutton {
	.button;

	min-width: 120px;
	min-height: 15px;

	color: @toolbutton_text_default;
	background-color: @toolbutton_bg_default;
	font: @toolbutton_font;
	padding: @toolbutton_padding;
	border: @toolbutton_border_default;
	border-radius: @toolbutton_radius;
	box-shadow: @toolbutton_shadow;
	outline: none;

	@media @mobile {
		min-width: 90px;
	}

	& when(@brand="Hema") {
		font-family: @fontFamilyDemiBold;
		font-weight: @fontWeightDemiBold;
		text-transform: lowercase;
	}

	&:not(.disabled):hover {
		color: @toolbutton_text_hover;
		border: @toolbutton_border_hover;
	}

	&.pressed {
		background: @toolbutton_bg_pressed;
		color: @toolbutton_text_pressed;
	}

	&.disabled {
		background: @toolbutton_bg_disabled;
		color: @toolbutton_text_disabled;
		cursor: not-allowed;
	}
}

.facebookButton {
	background: #3b5998 !important;
	color: #fff !important;

	&:not(.disabled):hover {
		background: #2c406e;
	}
}

.toolButton {
	width: 60px;
	height: 50px;
	position: relative;
	box-shadow: none;
	border: 0;
	cursor: pointer;
	background-color: transparent;
	outline: none;

	.background {
		color: #fff;
		font-size: 40px;
		position: absolute;
		top: 0;
		left: 10px;
	}

	.foreground {
		color: #464646;
		font-size: 22px;
		position: absolute;
		top: 9px;
		left: 19px;
	}

	&:disabled {
		opacity: 0.5;
	}

	&:hover {
		.background {
			color: #ccc;
		}
	}

	&.inverse {
		.background {
			color: #323232;
		}

		.foreground {
			color: #fff;
		}

		.subscript {
			color: #464646;
		}
	}

	&.focus {
		.background {
			color: @colorPalette1;
		}

		.foreground {
			color: #fff;
		}

		.subscript {
			color: #464646;
		}
	}

	.subscript {
		position: absolute;
		top: 40px;
		left: 0;
		width: 60px;
		text-align: center;
		font-size: @fontSize_XXS2;
		font-family: @fontFamilyRegular;
		white-space: normal;
		overflow-wrap: break-word;

		& when(@brand="Hema") {
			text-transform: lowercase;
		}
	}
}
