@import './components/contentblock.less';

:global {
	@import "basis.less";
	@import 'vendor/shepherd.less';
	@import (less) "vendor/pikaday.css";

	@import "vendor/fa/fontawesome.less";
	@import "vendor/fa/fa-brands.less";
	@import "vendor/fa/fa-solid.less";
	@import "vendor/fa/fa-regular.less";
	@import "vendor/fa/fa-regular.less";

	@import '~labelIcons';

	& when(@brand="Hema") {
		@font-face {
			font-family: 'HEMA-foto-icons';
			src: url(../../webfonts/hema-icons/regular.woff?vg9642) format("woff");
			font-weight: 400;
			font-style: normal;
			font-display: swap;
		}
	}

	/* & when(@brand="Hema") {
		@font-face {
			font-family: 'HemaDemibold';
			src: url(../../webfonts/futurahemapro-2021/demibold.woff2) format("woff");
			font-weight: 400 !important;
			font-style: normal;
			font-display: swap;
		}
	} */

	& when(@brand="Hema") {
		@font-face {
			font-family: 'HurmeHEMA';
			src: url(../../webfonts/hurmeHEMA/regular.woff2) format("woff");
			font-weight: 200;
			font-style: normal;
			font-display: swap;
		}

		@font-face {
			font-family: 'HurmeHEMA';
			src: url(../../webfonts/hurmeHEMA/regular.woff2) format("woff");
			font-weight: 400;
			font-style: normal;
			font-display: swap;
		}

		@font-face {
			font-family: 'HurmeHEMA';
			src: url(../../webfonts/hurmeHEMA/demibold.woff2) format("woff");
			font-weight: 600;
			font-style: normal;
			font-display: swap;
		}

		@font-face {
			font-family: 'HurmeHEMA';
			src: url(../../webfonts/hurmeHEMA/bold.woff2) format("woff");
			font-weight: 700;
			font-style: normal;
			font-display: swap;
		}
	}
}
