@import "./less.less";

:global {
	:root {
		--font-family-regular: @fontFamilyRegular;
		--font-family-demi-bold: @fontFamilyDemiBold;
		--font-family-bold: @fontFamilyBold;
		--font-weight-regular: @fontWeightRegular;
		--font-weight-demi-bold: @fontWeightDemiBold;
		--font-weight-bold: @fontWeightBold;

		--font-size-xxxs: @fontSize_XXXS_New;
		--font-size-xxxs1: @fontSize_XXXS1_New;
		--font-size-xxxs2: @fontSize_XXXS2_New;
		--font-size-xxxs3: @fontSize_XXXS3_New;
		--font-size-xxs: @fontSize_XXS_New;
		--font-size-xxs1: @fontSize_XXS1_New;
		--font-size-xxs2: @fontSize_XXS2_New;
		--font-size-xxs3: @fontSize_XXS3_New;
		--font-size-xs: @fontSize_XS_New;
		--font-size-xs1: @fontSize_XS1_New;
		--font-size-xs2: @fontSize_XS2_New;
		--font-size-xs3: @fontSize_XS3_New;
		--font-size-s: @fontSize_S_New;
		--font-size-s1: @fontSize_S1_New;
		--font-size-s2: @fontSize_S2_New;
		--font-size-s3: @fontSize_S3_New;
		--font-size-m: @fontSize_M_New;
		--font-size-m1: @fontSize_M1_New;
		--font-size-m2: @fontSize_M2_New;
		--font-size-m3: @fontSize_M3_New;
		--font-size-l: @fontSize_L_New;
		--font-size-l1: @fontSize_L1_New;
		--font-size-l2: @fontSize_L2_New;
		--font-size-l3: @fontSize_L3_New;
		--font-size-xl: @fontSize_XL_New;
		--font-size-xl1: @fontSize_XL1_New;
		--font-size-xl2: @fontSize_XL2_New;
		--font-size-xl3: @fontSize_XL3_New;
		--font-size-xxl: @fontSize_XXL_New;
		--font-size-xxl1: @fontSize_XXL1_New;
		--font-size-xxl2: @fontSize_XXL2_New;
		--font-size-xxl3: @fontSize_XXL3_New;
		--font-size-xxxl: @fontSize_XXXL_New;
		--font-size-xxxl1: @fontSize_XXXL1_New;
		--font-size-xxxl2: @fontSize_XXXL2_New;
		--font-size-xxxl3: @fontSize_XXXL3_New;
		--font-size-xxxxl: @fontSize_XXXXL_New;
		--font-size-xxxxl1: @fontSize_XXXXL1_New;
		--font-size-xxxxl2: @fontSize_XXXXL2_New;
		--font-size-xxxxl3: @fontSize_XXXXL3_New;
		--font-size-xxxxxl: @fontSize_XXXXXL_New;
		--font-size-xxxxxl1: @fontSize_XXXXXL1_New;
		--font-size-xxxxxl2: @fontSize_XXXXXL2_New;
		--font-size-xxxxxl3: @fontSize_XXXXXL3_New;
		--font-size-xxxxxxl: @fontSize_XXXXXXL_New;
		--font-size-xxxxxxl1: @fontSize_XXXXXXL1_New;
		--font-size-xxxxxxl2: @fontSize_XXXXXXL2_New;
		--font-size-xxxxxxl3: @fontSize_XXXXXXL3_New;
		--font-size-x2l: @fontSize_X2L_New;
		--font-size-x3l: @fontSize_X3L_New;

		--font-transform: @fontTransform;

		--dialog-z-index: @dialogZindex;

		--color-picker-neutral-1: #fff;
		--color-picker-neutral-2: #f5f5f5;
		--color-picker-neutral-3: #dcdcdc;
		--color-picker-neutral-4: #acacac;
		--color-picker-neutral-5: #535353;
		--color-picker-neutral-6: #000;
		--color-picker-neutral-slider: var(--color-picker-neutral-1);

		--color-picker-red-1: #ffb3b3;
		--color-picker-red-2: #f66;
		--color-picker-red-3: red;
		--color-picker-red-4: #c00;
		--color-picker-red-5: #900;
		--color-picker-red-6: #600;
		--color-picker-red-slider: var(--color-picker-red-3);

		--color-picker-orange-1: #ffd9b3;
		--color-picker-orange-2: #ffb266;
		--color-picker-orange-3: #ff7f00;
		--color-picker-orange-4: #c60;
		--color-picker-orange-5: #994c00;
		--color-picker-orange-6: #630;
		--color-picker-orange-slider: var(--color-picker-orange-3);

		--color-picker-yellow-1: #fff2b3;
		--color-picker-yellow-2: #ffe666;
		--color-picker-yellow-3: #ffd500;
		--color-picker-yellow-4: #ca0;
		--color-picker-yellow-5: #998000;
		--color-picker-yellow-6: #650;
		--color-picker-yellow-slider: var(--color-picker-yellow-3);

		--color-picker-green-1: #c7f7c7;
		--color-picker-green-2: #8fef8f;
		--color-picker-green-3: #45e445;
		--color-picker-green-4: #37b737;
		--color-picker-green-5: #298929;
		--color-picker-green-6: #1c5c1c;
		--color-picker-green-slider: var(--color-picker-green-3);

		--color-picker-turquoise-1: #b3f2ff;
		--color-picker-turquoise-2: #66e5ff;
		--color-picker-turquoise-3: #00d4ff;
		--color-picker-turquoise-4: #0ac;
		--color-picker-turquoise-5: #007f99;
		--color-picker-turquoise-6: #056;
		--color-picker-turquoise-slider: var(--color-picker-turquoise-3);

		--color-picker-blue-1: #b3ccff;
		--color-picker-blue-2: #69f;
		--color-picker-blue-3: #05f;
		--color-picker-blue-4: #04c;
		--color-picker-blue-5: #039;
		--color-picker-blue-6: #026;
		--color-picker-blue-slider: var(--color-picker-blue-3);

		--color-picker-purple-1: #d9b3ff;
		--color-picker-purple-2: #b366ff;
		--color-picker-purple-3: #8000ff;
		--color-picker-purple-4: #60c;
		--color-picker-purple-5: #4d0099;
		--color-picker-purple-6: #306;
		--color-picker-purple-slider: var(--color-picker-purple-3);

		--color-picker-pink-1: #ffb3f2;
		--color-picker-pink-2: #ff66e5;
		--color-picker-pink-3: #ff00d4;
		--color-picker-pink-4: #c0a;
		--color-picker-pink-5: #99007f;
		--color-picker-pink-6: #605;
		--color-picker-pink-slider: var(--color-picker-pink-3);

		/* Light theme */
		.light {
			// Background
			--background-primary: #fff;
			--background-primary-inverse: #262626;
			--background-primary-translucent: rgba(#fff, 0.9);
			--background-secondary: #f0f0f0;
			--background-tertiary: #fff;
			--background-quaternary: #f0f0f0;

			// Font
			--font-primary: #262626;
			--font-secondary: #fff;
			--font-on-background-fill: #262626;
			--font-disabled: rgba(#262626, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #262626;
			--font-neutral-grey1: #999999;

			// Icon
			--icon-default: #262626;
			--icon-active: #fff;
			--icon-disabled: rgba(#262626, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #262626;
			--icon-neutral-grey1: #f0f0f0;
			--icon-neutral-inverse: #fff;

			// Neutrals
			--white1: #fff;
			--white2-disabled: rgba(#fff, 0.3);
			--black1: #262626;
			--black2-disabled: rgba(#262626, 0.3);
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#262626, 0.1);
			--neutral3-background-fill: #f0f0f0;
			--neutral4-grey: #999;
			--neutral5-slider-step: #e4e4e4;
			--neutral6-grey-fill: #d9d9d9;

			// Colors
			--menu-selection: #65acff;
			--primary1: #65acff;
			--primary1-disabled: rgba(#65acff, 0.3);
			--primary2: #518fd8;
			--primary2-disabled: rgba(#518fd8, 0.3);
			--accent1: #65acff;
			--accent2: #65acff;
			--accent3: #ffdb4d;
			--accent4: #73b3ff;
			--alert-info: #3333ff;
			--alert-warning: #ffdb4d;
			--gridlines: #ff3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);

			// Select
			--select-background-primary: #fff;
			--select-background-secondary: #fff;
			--select-options-background-primary: #f0f0f0;
			--select-options-background-secondary: #fff;

			// Input Slider
			--input-slider-accent: #65acff;
			--input-slider-background: rgba(#262626, 0.1);
			--input-slider-handle-border: #fff;
		}

		/* Dark theme */
		.dark {
			// Background
			--background-primary: #181818;
			--background-primary-inverse: #fff;
			--background-primary-translucent: rgba(#181818, 0.9);
			--background-secondary: #262626;
			--background-tertiary: #181818;
			--background-quaternary: #181818;

			// Font
			--font-primary: #fff;
			--font-secondary: #262626;
			--font-on-background-fill: #262626;
			--font-disabled: rgba(#fff, 0.3);
			--font-neutral-white1: #fff;
			--font-neutral-black1: #262626;
			--font-neutral-grey1: #999999;

			// Icon
			--icon-default: #fff;
			--icon-active: #262626;
			--icon-disabled: rgba(#fff, 0.3);
			--icon-neutral-white1: #fff;
			--icon-neutral-black1: #262626;
			--icon-neutral-grey1: #f0f0f0;
			--icon-neutral-inverse: #262626;

			// Neutrals
			--white1: #fff;
			--white2-disabled: rgba(#fff, 0.3);
			--black1: #262626;
			--black2-disabled: rgba(#262626, 0.3);
			--neutral1-borders-and-stroke: #e7e7e7;
			--neutral2-separator-and-divider: rgba(#fff, 0.1);
			--neutral3-background-fill: #f0f0f0;
			--neutral4-grey: #999;
			--neutral5-slider-step: #e4e4e4;
			--neutral6-grey-fill: #d9d9d9;

			// Colors
			--menu-selection: #65acff;
			--primary1: #65acff;
			--primary1-disabled: rgba(#65acff, 0.3);
			--primary2: #518fd8;
			--primary2-disabled: rgba(#518fd8, 0.3);
			--accent1: #65acff;
			--accent2: #65acff;
			--accent3: #ffdb4d;
			--accent4: #73b3ff;
			--alert-info: #3333ff;
			--alert-warning: #ffdb4d;
			--alert-warning2: #ed4141;
			--gridlines: #ff3333;

			// Material Fills
			--light1: rgba(#fff, 0.1);
			--dark1: rgba(#232323, 0.5);
			--dark2: rgba(#000, 0.5);

			// Select
			--select-background-primary: #262626;
			--select-background-secondary: #181818;
			--select-options-background-primary: #262626;
			--select-options-background-secondary: #181818;

			// Input Slider
			--input-slider-accent: #65acff;
			--input-slider-background: #f0f0f0;
			--input-slider-handle-border: #f0f0f0;
		}
	}
}
