@import '~lessVars';

:global {
	.contentBlock {
		float: left;
		width: 100%;
	}

	.workSpaceWrapper {
		width: 100%;
		float: left;
		display: block;

		& p {
			padding: 8px 8px 8px 20px;
		}
	}

	.workSpace {
		float: left;
		width: 100%;


		& .text {
			width: 100%;
			float: left;

			@media @mobile {
				text-align: center;
			}
		}
	}

	.contentBlockHeader,
	.workSpaceFooter {
		width: 100%;
		float: left;
		display: block;
		text-align: center;

		@media @mobile {
			padding: 5px;
		}

		@media @desktop {
			padding: 10px;
		}
	}

	.contentBlockHeader {
		@media @mobile {
			padding: 0 0 5px;
		}

		.headerText {
			float: left;

			@media @size480 {
				width: 100%;
				text-align: center;
			}
		}

		h2,
		.description,
		.contentBlockHeaderIcon {
			margin: 0;

			@media @mobile {
				padding: 5px;
			}

			@media @desktop {
				padding: 0 15px;
				float: left;
				clear: left;
			}
		}

		.description {
			color: @black;

			@media @mobile {
				color: @black;
				background: none;
			}
		}

		h2 {
			font: @header_font;
			color: #2779A1;

			& when(@brand="Hema") {
				color: @black;
			}
		}

		.contentBlockHeaderIcon {
			float: right;
			color: @brandcolor2;
			cursor: pointer;
		}
	}
}
