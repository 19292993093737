
@import '~lessVars';

.introText,
.address {
	float: left;
	width: 100%;

	& when(@brand="Hema") {
		text-transform: none;
	}
}
.address {
	padding: 20px 0 0;
	font-family: @fontFamilyDemiBold;
	font-weight: @fontWeightDemiBold;
}
