
@import '~lessVars';
@import '~root/css/components/animation.less';

.fileConversionView {
	float: left;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 25px;
	font-size: @fontSize_XS2;
}
.mainHeader {
	float: left;
	width: 100%;
	font-size: @fontSize_S;
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;

	& when(@brand="Hema") {
		text-transform: lowercase;
	}
}
.subHeader {
	float: left;
	width: 100%;
	font-size: @fontSize_XS;
}
