
@import '~root/css/components/animation.less';
@import '~root/css/components/button.less';
@import '~root/css/components/input.less';

.selectBox {
    margin: 2px 0;

    @media @mobile {
        width: 100%;
    }
    @media @desktop {
        width: 330px;
        max-width: 100%;
    }
}
