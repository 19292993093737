
@import '~lessVars';

.fabButton {
	border-radius: 50%;
	cursor: pointer;
	height: 40px;
	overflow: hidden;
	position: fixed;
	transform: translate3d(0, 0, 0);
	transition: background-color .2s .1s, bottom .2s .1s, height .2s .1s, width .2s .1s;
	width: 40px;
	z-index: 100;

	&:not(.fabButtonNew) {
		background-color: #9aca41;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 15px -5px;

		&.open {
			background-color: #9aca41;
			height: 30px;
			transition: background-color .2s, width .2s, height .2s;
			width: 30px;

			.iconClosed {
				opacity: 0;
				transition: opacity .2s;
			}

			.iconOpen {
				opacity: 1;
				transition: opacity .2s .1s;
			}
		}

		.iconClosed {
			height: 20px;
			margin: 0;
			margin-top: 10px;
			transition: opacity .2s .1s;
			width: 40px;
		}

		.iconOpen {
			height: 14px;
			left: 0;
			margin: 8px 0 0 0;
			opacity: 0;
			position: absolute;
			top: 0;
			transition: opacity .2s;
			width: 30px;
		}
	}

	&.fabButtonNew {
		align-items: center;
		display: flex;
		justify-content: center;

		.icon-component {
			background-color: var(--white1);
			font-size: @fontSize_XXXXXXL_New;

			&.iconClosed {
				color: var(--primary1);
			}

			&.iconOpen,
			&:hover {
				color: var(--primary2);
			}
		}
	}
}

.fabMenu {
	position: fixed;
	width: 200px;
	height: auto;
	background-color: white;
	z-index: 999;
	padding: 16px;
	border-radius: 2px;
	font-size: @fontSize_XS;
	opacity: 0;
	transform: translate(0px, 0px) translateY(20px);
	pointer-events: none;
	transition: opacity .2s, transform .2s;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 12px 0px;

	&.open {
		opacity: 1;
		transform: translate(0px, 0px) translateY(0px);
		pointer-events: auto;
		transition: opacity .2s .1s, transform .2s .1s;
	}

	ul {
		display: flex;
		flex-direction: column;
		margin-top: 8px;
		list-style: none;
		padding: 0;

		li {
			margin: 5px 0px;
			background-color: #f1efeb;
			padding: 6px 8px;
			cursor: pointer;
			border-radius: 2px;
			transition: background-color .2s;

			&:hover {
				margin: 5px 0px;
				background-color: #C6C2BB;
			}
		}
	}
}
