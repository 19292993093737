
.itemsWrapper {
    max-height: 250px;
    overflow-y: auto;

    .preview {
        display: inline-block;
        margin: 10px;
    }

    .image {
		max-width: 100px;
		max-height: 100px;
	}
}
