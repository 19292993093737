
@import '~lessVars';

.switch-box-component {
	align-items: center;
	display: flex;
	justify-content: space-between;

	input {
		display: none;
	}

	label {
		cursor: pointer;
		padding-right: 8px;
		user-select: none;
	}

	.switch-box {
		@height: 24px;
		@padding: 2px;
		@width: 40px;
		@handle-size: @height - (@padding * 2);
		@handle-transform: (@width - @padding * 2 - @handle-size);
		align-items: center;
		background-color: var(--background-primary-inverse);
		border-radius: calc(@height / 2);
		cursor: pointer;
		display: flex;
		height: @height;
		padding: @padding;
		width: @width;

		&.checked {
			background-color: var(--primary1);

			.switch-box-handle {
				background-color: var(--white1);
				transform: translateX(@handle-transform);
			}
		}

		.switch-box-handle {
			background-color: var(--background-secondary);
			border-radius: calc(@height * 2);
			height: @handle-size;
			transition: transform 0.4s ease;
			width: @handle-size;
		}
	}
}
