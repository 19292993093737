@import '~lessVars';

.loadWrapper {
	float: left;
	width: 100%;
	text-align: center;
	padding-top: 30px;
}
.picker {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	float: left;
	display: grid;

	&.one {
		grid-template-columns: auto;
		row-gap: 20px;
	}
	&.two {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 20px;
		row-gap: 20px;
	}
	&.three {
		grid-template-columns: repeat(3, 1fr);
		column-gap: 20px;
		row-gap: 20px;
	}

	.pickerItem {
		display: inline-block;
		padding: 0;
		cursor: pointer;
		position: relative;
		text-align: center;

		.selectedIcon {
			position: absolute;
			right: ~"calc(50% - 16px)";
			top: ~"calc(50% - 16px)";
			width: 32px;
			height: 32px;
			border-radius: 16px;
			background: #fff;
			text-align: center;
			color: #9ac122;
			cursor: pointer;

			i {
				line-height: 32px;
			}
		}
	}
}
