
@import '~lessVars';

.savingProject {
	float: left;
	width: 100%;
	text-align: center;
}
.textNode {
	display: block;
	clear: both;
}
.mainMessage {
	margin-top: 10px;
}
.cancel {
	margin-top: 25px;
	text-decoration: underline;
	cursor: pointer;
}

