
.loadingDialog {
	font-size: 1em;
	margin: 0 auto;
	width: 64px;

	.loaderText {
		display: inline-block;
		padding-top: 5px;
		text-align: center;
		width: 100%;
	}
}
