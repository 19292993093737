
@import '~lessVars';
@import '~root/css/components/animation.less';

.progressBox {
	width: 100%;

	.progressText {
		margin: 5px auto;
		display: block;
		width: 100%;
		max-width: 650px;

		& when(@brand="Hema") {
			text-transform: lowercase;
		}

		.percentageCompleted {
			font-size: @fontSize_XS;
			font-weight: @fontWeightDemiBold;
			font-family: @fontFamilyDemiBold;
		}
	}
	.progressBar {
		height: 8px;
		width: 100%;
		max-width: 650px;
		display: block;
		margin: 5px auto;
		border-radius: 20px;
		background-color: #D6EDF9;

		.progressValue {
			height: inherit;
			display: block;
			background-color: @colorPalette1;
			border-radius: 20px;
		}
	}
	.progressStatus {
		width: 100%;
		text-align: center;
		color: var(--font-neutral-black1);
		font-size: @fontSize_XS;
		font-weight: @fontWeightDemiBold;
		font-family: @fontFamilyDemiBold;

		& when(@brand="Hema") {
			text-transform: lowercase;
		}
	}
}
