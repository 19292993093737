
@import '~lessVars';
@import '~root/css/components/input.less';

.listItem {
	list-style: none;
	float: left;
    margin: 0;
    width: 100%;
    padding-bottom: 10px;

    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }

    .upsellHeader {
        float: left;
        width: 100%;
        font-size: @fontSize_XS;
        text-align: left;
        padding-bottom: 10px;
    }
	.upsellContentBox {
		display: flex;

		.itemThumbnail {
			padding: 0 10px;

			img {
				height: 100px;
				max-width: 100px;
			}
		}
		.itemInfo {
			text-align: left;

			.itemName,
			.itemPrice,
			.itemDescription {
				width: 100%;
				display: inline-block;
			}
			.itemName {
				font-weight: 700;
				padding-bottom: 5px;
			}
			.itemPrice {
				padding-top: 5px;
			}
		}
		.itemQuantity {
			padding-left: 10px;

			.selectBox {
				width: 60px;
			}
		}
	}
}
